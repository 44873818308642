const objectfit = function (el) {
    if (!('object-fit' in document.body.style)) {
        let initObjectFit = () => {
            let objectFitContainer;

            if (!el || !el.length) {
                objectFitContainer = document.querySelectorAll('.object-fit');
            } else {
                objectFitContainer = el.querySelectorAll('.object-fit');
            }

            // convert to Array for IE
            objectFitContainer = Array.prototype.slice.call(objectFitContainer);

            const objectFitFromImg = function (container, img) {
                let imgUrl = img.src,
                    imgUrlAsync = img.getAttribute('data-src'),
                    objectfitFallbackUrl = img.getAttribute('data-objectfit');

                if (imgUrlAsync) {
                    imgUrl = imgUrlAsync;
                }

                if (objectfitFallbackUrl) {
                    imgUrl = objectfitFallbackUrl;
                }

                if (imgUrl) {
                    container.style.backgroundImage = `url(${imgUrl})`;
                    container.classList.add('compat-object-fit');
                }
            };

            objectFitContainer.forEach(container => {
                let img = container.querySelector('img');

                if (!img) {
                    return;
                }

                img.addEventListener('load', objectFitFromImg(container, img));

                // if the image was faster than the script, trigger the loading event manually
                if (img.complete && !container.classList.contains('compat-object-fit')) {
                    objectFitFromImg(container, img);
                }
            });
        };

        // due to async loading, the DOMContentLoaded event is probably already triggered
        if (document.readyState === 'complete'
            || document.readyState === 'loaded'
            || document.readyState === 'interactive') {
            initObjectFit();
        } else {
            document.addEventListener('DOMContentLoaded', initObjectFit);
        }
    }
};

objectfit();

export default objectfit;
