// ////////////////////////////////////////////////////////////
// include needed polyfills
import './polyfill/index';

// ////////////////////////////////////////////////////////////
// image lazy loading
import 'lazysizes';

// ////////////////////////////////////////////////////////////
// check if SVGs are working at all
if (document.createElementNS && document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect) {
    document.documentElement.classList.add('svg');
}

// ////////////////////////////////////////////////////////////
// check if cookies are enabled
// - when not we add the .no-cookies class to html
let cookieEnabled = (navigator.cookieEnabled) ? true : false;

if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
    document.cookie = 'testcookie';
    cookieEnabled = (document.cookie.indexOf('testcookie') != -1) ? true : false;
}

if (!cookieEnabled) {
    document.getElementsByTagName('html')[0].setAttribute('class', 'no-cookies');
}
